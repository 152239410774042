export const trans = (path, wildcards) => {
    return window.appLocale.trans(path, wildcards)
}

export const ucfirst = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export function getMetaTagContent(name, defaultOpt = null) {
    let result = defaultOpt
    try {
        result = document.querySelector(`meta[name="${name}"]`).getAttribute('content')
    } catch (e) {
        result = defaultOpt;
    }
    return result;
}
export const extractJWTData = (tok) => {
    if (!tok) return [null, 'JWT is required'];
    try {
        const jwtParts = tok.split('.')
        const data = JSON.parse(atob(jwtParts[1]));
        return [data, null]
    } catch (error) {
        return [null, 'Unable to extract user information from Google JWT']
    }
}

export function getElement(selector) {
    let result = null
    try {
        result = document.querySelector(selector);
    } catch (e) {
        result = null;
    }
    return result;
}
export function getElements(selector) {
    let result = null
    try {
        result = document.querySelectorAll(selector);
    } catch (e) {
        result = null;
    }
    return result;
}

export function deleteElements(selector) {
    let elements = getElements(selector);
    if (elements !== null && elements.length) {
        [...elements].forEach(el => {
            el.remove()
        });
    }
}
export function deleteElement(element) {
    if (!(element instanceof HTMLElement)) {
        element = getElement(element);
    }

    element.remove();
}

export function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getQueryStringValue(key) {
    let result = decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"))

    return result != '' ? result : null;
}

export function parseQueryString() {
    let decodedUrl = decodeURIComponent(window.location.search.substring(1));
    let urlComponents = decodedUrl.split('&');
    let result = {};
    urlComponents.forEach(component => {
        let componentItems = component.split('=');
        let matched = Array.from(/([a-z_]+)/g[Symbol.matchAll](componentItems[0]), x => x[0]);
        if (matched.length > 1) {
            try {
                result[matched[0]] = Object.assign({}, result[matched[0]], { [matched[1]]: componentItems[1] });
            } catch (error) {
                result[matched[0]] = { [matched[1]]: componentItems[1] };
            }
        } else {
            result[componentItems[0]] = componentItems[1];
        }
    })
    return result;
}

export function scrollTo(selector, speed = 1000) {
    if ("#%20" == selector) {
        return null;
    }
    let scrollLocation = $(selector).offset().top - ($(selector).height() + 70);

    return $('html:not(:animated), body:not(:animated)').animate({
        scrollTop: scrollLocation
    }, speed).promise();
}

/**
 * 
 * @param { null|DOMElement } el 
 * @param {string} selector
 *  
 * @return { null|DOMElement } result 
 */
export const findIn = (el, selector) => {
    try {
        return el.querySelector(selector)
    } catch (e) {
        return null
    }
}
/**
 * 
 * @param { null|DOMElement } el 
 * @param {string} selector
 *  
 * @return { Array<DOMElement> } result 
 */
export const findAllIn = (el, selector) => {
    try {
        return el.querySelectorAll(selector)
    } catch (e) {
        return []
    }
}

/**
 * Go through array of selectors, trying to fetch and element
 * 
 * @param {Array} arrayOfSelectors 
 *
 * @return null|DOMElement 
 */
export function findFirst(arrayOfSelectors) {
    let resultElem = null;

    arrayOfSelectors.some((selector) => {
        let el = getElement(selector);
        if (nodeExists(el)) {
            resultElem = el;
            return true;
        }
    })

    return resultElem;
}

export function nodeExists(element) {
    return element != null && element != undefined;
}

export function parseBoolean(string) {
    let boolean;

    if (typeof string === 'boolean') {
        return string;
    }

    if (parseInt(string) > 0) {
        return true;
    }

    switch (string) {
        case 1:
        case '1':
        case 'true':
            boolean = true;
            break;
        case undefined:
        case 'false':
        case '0':
        case 0:
            boolean = false;
            break;
        default:
            throw Error(`Could not parse "${string}" in boolean`);
    }

    return boolean;
}

export function isMobile() {
    var isMobile = false; //initiate as false
    // device detection
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) isMobile = true;

    return isMobile;
}

export function addHandlers(handlerObject, eventType = "click") {
    let elements = getElements("[data-trigger]");
    try {
        elements.forEach(element => {
            let handler = element.getAttribute("data-trigger");
            if (handlerObject[handler] !== undefined && typeof handlerObject[handler] === 'function') {
                element.addEventListener(eventType, handlerObject[handler]);
            }
        });
    } catch (error) {
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index];
            let handler = element.getAttribute("data-trigger");
            if (handlerObject[handler] !== undefined && typeof handlerObject[handler] === 'function') {
                element.addEventListener(eventType, handlerObject[handler]);
            }
        }
    }
}

/**
 * Check if object can be called as a function (callback)
 * 
 * @param {Object} callable
 * @return {Boolean} 
 */
export function isCallable(callable) {
    return (typeof callable === 'function');
}

export function makeElement(templateString) {
    const nodeElement = document.createElement('span');
    nodeElement.innerHTML = templateString;
    return nodeElement.childNodes[0];
}

export function emptyElement(selector) {
    const nodeElement = getElement(selector);
    nodeElement.innerHTML = '';
    return nodeElement;
}

export function extractResponseMessages(apiResponse) {
    let responseMessages = [];
    if (apiResponse.hasOwnProperty('errors') && apiResponse.errors[Object.keys(apiResponse.errors)[0]].length) {
        let responseResult = apiResponse.errors[Object.keys(apiResponse.errors)[0]];
        if (!Array.isArray(responseResult)) {
            responseResult = [responseResult]
        }

        try {
            responseMessages.push(entry.error.message);
        } catch (error) {
            responseMessages.push("Whoops... Something went wrong. Please try again later.")
        }

    } else {
        let responseResult = apiResponse.successes[Object.keys(apiResponse.successes)[0]];
        if (!Array.isArray(responseResult)) {
            responseResult = [responseResult]
        }
        responseResult.forEach(entry => {
            try {
                responseMessages.push(entry.success.message);
            } catch (error) {

            }
        });
    }
    return responseMessages;
}

export const getProfileConfig = () => {
    return new Promise((resolve, reject) => {
        $.ajax({
            method: "GET",
            url: '/api/profile-config',
            success: resolve,
            error: reject
        });
    })
}
export const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}
// darker
export const rgbShade = (rgb, factor) => {
    const shadeFactor = 1 - Math.min(Math.max(factor, 0), 1);
    return {
        r: Math.floor(rgb.r * shadeFactor),
        g: Math.floor(rgb.g * shadeFactor),
        b: Math.floor(rgb.b * shadeFactor)
    }
}

// lighter
export const rgbTint = (rgb, factor) => {
    const tintFactor = Math.min(Math.max(factor, 0), 1)
    return {
        r: Math.floor(rgb.r + (255 - rgb.r) * tintFactor),
        g: Math.floor(rgb.g + (255 - rgb.g) * tintFactor),
        b: Math.floor(rgb.b + (255 - rgb.b) * tintFactor)
    }
}
// Generate contrast color 
export const generateVisibleTextColor = (rgb) => {
    let luminosity = (0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b) / 255;
    return luminosity > .5 ? 'rgb(51,51,51)' : 'rgb(255,255,255)';
}

export const toRGBStyle = (rgb) => `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;